<template>
  <div
    v-if="pokerSession.activeTask"
    class="cards-table"
  >
    <div
      v-for="card in pokerSession.activeTask.votes"
      :key="card.userUuid"
    >
      <div
        v-if="!openedCards"
        class="not-voted-card"
      >
        <div>
          {{ pokerSession.users.find(el => el.uuid === card.userUuid).firstName }}
        </div>
        <div>
          {{ pokerSession.users.find(el => el.uuid === card.userUuid).lastName }}
        </div>
      </div>
      <div
        v-if="openedCards"
        class="opened-card"
      >
        <div class="point-first">
          {{ card.score }}
        </div>
        <div class="point-middle">
          {{ card.score }}
        </div>
        <div class="point-third">
          {{ card.score }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardTable',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
    openedCards: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      clicked: false,
    };
  },
};

</script>

<style scoped>
    .cards-table {
        display: flex;
        justify-content: center;
        align-items: center;
      height: 100%;
    }
  .not-voted-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: gray;
    margin: 5px;
    background: #f4f4f5;
  }
  .opened-card {
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: gray;
    margin: 5px;
    background: #f4f4f5;
  }
    .point-first {
      padding: 10px;
      font-size: 10px;
      flex-grow: 1;
    }
    .point-middle {
      padding: 10px;
      flex-grow: 1;
      font-size: 37px;
      display: flex;
      justify-content: center;
    }
    .point-third {
      padding: 10px;
      font-size: 10px;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      transform: rotate(180deg);
    }
</style>
